export default { 
  h2929b8bdec309e9156cb6fbe5deadf43: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. The App Store is a registered trademark of Apple Inc."], {}]],
  h2a1b740abc2df27c029a5d4f9eeb3f9e: [[({}, []) => ["Google Play and the Google Play logo are trademarks of Google LLC."], {}]],
  h5c38e717cc4197578c06fa3b4d6d2d24: [[({}, []) => ["Login to see Terms & Conditions and Privacy Policy."], {}], [({}, []) => ["Login to see Deposit Account Agreement and Privacy Policy."], {
  "programType": ["consumer_dda"]
}]],
  h6085060d347aadc30d224952edad80a4: [[({}, []) => ["All other trademarks and service marks belong to their respective owners."], {}]],
  ha401774f6a7c26d7c77afb1a477e05e4: [[({}, []) => ["Login to see Terms & Conditions or Deposit Account Agreement and Privacy Policy."], {}]],
  haa5df253477335c7375c6ea7c7440f22: [[({
  mfeConfig: mfeConfig
}, [C0]) => ["Version ", C0(mfeConfig.version)], {}]],
  hefba0b24ecbb85b189d83f7c13244339: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. The App Store is a registered trademark of Apple Inc."], {}]] 
}