export default { 
  h0058e1a94448f774133957081c538246: [[({
  mfeConfig: mfeConfig
}, [C0]) => ["Version ", C0(mfeConfig.version)], {}]],
  h0dbc73786b6bddfd4d165323c0b181e7: [[({}, []) => ["All other trademarks and service marks belong to their respective owners."], {}]],
  h182ccace07bf5cc185356d7069575d77: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. Apple Inc."], {}]],
  h3acbf0fbcf5d46d5d2f92af89f694c46: [[({}, [C0]) => ["Mastercard and the circles design are trademark of Mastercard International Incorporated.", C0()], {}]],
  h46f6edd624eb2cd4e909cf465c40406a: [[({}, []) => ["Google Play and the Google Play logo are trademarks of Google LLC."], {}]],
  h5702f5ae3a9a5524e0b23a656f3dd183: [[({}, []) => ["Login to see Terms & Conditions or Deposit Account Agreement and Privacy Policy."], {}]],
  h7dafb9baddd0cbaa3726d87c3bdb6ce9: [[({}, []) => ["Login to see Terms & Conditions and Privacy Policy."], {}], [({}, []) => ["Login to see Deposit Account Agreement and Privacy Policy."], {
  "programType": ["consumer_dda"]
}]],
  h7ef4dbd954c7aaa45a8cf99a37d866ef: [[({}, [C0, C1]) => ["Apple", C0("\xAE"), " and the Apple logo", C1("\xAE"), " are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc."], {}]],
  hebbb6d0549c3a96316dacd6f3aec58cc: [[({}, []) => ["Card may be used everywhere Debit Mastercard is accepted."], {}]] 
}