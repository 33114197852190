import __NS_DYNAMIC_REACT_CONTENT___0 from "./paypal-unauthed-footer.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
import { Bold, Disclosure as BaseDisclosure } from 'legos/typography';
import { IssuerStatement } from 'apps/acp/packages/issuer-statement/react';
import { CopyrightStatement } from 'apps/acp/packages/content-copyright-statement';
import { isAndroid, isIOS, isWeb } from 'packages/platform-detector';
import { createUnAuthedGetBrandingRequest } from 'apps/acp/packages/webapi';
import { useHttpQuery } from 'packages/http-client/react';
import { showMobileAppsSection } from 'apps/acp/packages/mobile-apps-section-detector';
import { limitedUseAccessToken } from 'apps/acp/packages/limited-use-access-token';
import { AdaChatButton } from 'apps/acp/packages/ada-chatbot';
import { DisclosureStyle } from './styles/paypal-unauthed-footer.ccm.css';
const isCordova = isAndroid() || isIOS();
const Disclosure = DisclosureStyle.as(BaseDisclosure);
export const PayPalUnauthedFooter = ({
  mfeConfig
}) => {
  const {
    brand
  } = useHttpQuery(limitedUseAccessToken(createUnAuthedGetBrandingRequest()));
  return createElement(Fragment, null, mfeConfig.showLoginTermsAndConditionsDisclosure && createElement(Disclosure, null, mfeConfig.showChangedTermsDepositPrivacyDisclosure && createElement(Content, {
    hash: "5702f5ae3a9a5524e0b23a656f3dd183",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), !mfeConfig.showChangedTermsDepositPrivacyDisclosure && createElement(Content, {
    hash: "7dafb9baddd0cbaa3726d87c3bdb6ce9",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), mfeConfig.importantPatriotActDisclosure && createElement(Disclosure, {
    dangerouslySetInnerHTML: {
      __html: mfeConfig.importantPatriotActDisclosure
    }
  }), createElement(Disclosure, null, createElement(IssuerStatement, null)), mfeConfig.showMastercardDisclosures && createElement(Disclosure, null, createElement(Content, {
    hash: "3acbf0fbcf5d46d5d2f92af89f694c46",
    devVariables: {},
    componentsAndExpressions: [() => !mfeConfig.showCardUseDisclosure && createElement(Fragment, {
      key: "showCardUseDisclosure"
    }, createElement("br", null), createElement("br", null), createElement(Content, {
      hash: "ebbb6d0549c3a96316dacd6f3aec58cc",
      devVariables: {},
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    }))],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), mfeConfig.showShortAppleDisclosure && createElement(Disclosure, null, createElement(Content, {
    hash: "182ccace07bf5cc185356d7069575d77",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement("sup", null, children), (...children) => createElement("sup", null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), !mfeConfig.showShortAppleDisclosure && !showMobileAppsSection(mfeConfig.programType || '', brand.ios_app_url) && createElement(Disclosure, null, createElement(Content, {
    hash: "7ef4dbd954c7aaa45a8cf99a37d866ef",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement("sup", null, children), (...children) => createElement("sup", null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), !showMobileAppsSection(mfeConfig.programType || '', brand.android_app_url) && createElement(Disclosure, null, createElement(Content, {
    hash: "46f6edd624eb2cd4e909cf465c40406a",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), (!showMobileAppsSection(mfeConfig.programType || '', brand.android_app_url) || !showMobileAppsSection(mfeConfig.programType || '', brand.ios_app_url)) && createElement(Disclosure, null, createElement(Content, {
    hash: "0dbc73786b6bddfd4d165323c0b181e7",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Disclosure, null, createElement(CopyrightStatement, {
    statement: mfeConfig.copyrightStatement
  })), isCordova && createElement(Disclosure, {
    onClick: () => {
      var _a, _b;

      return ((_a = mfeConfig) === null || _a === void 0 ? void 0 : _a.mobileAppVersionOnClick) && ((_b = mfeConfig) === null || _b === void 0 ? void 0 : _b.mobileAppVersionOnClick(true));
    },
    style: {
      textAlign: 'center'
    }
  }, createElement(Content, {
    hash: "0058e1a94448f774133957081c538246",
    devVariables: {
      mfeConfig: mfeConfig
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), isWeb() && createElement(AdaChatButton, null));
};
export default PayPalUnauthedFooter;